<script setup>
/* eslint-disable no-unused-vars */
import { onMounted, ref, reactive } from "vue";
import * as article from "@/http/article";
import { ElNotification } from "element-plus";

const loading = ref(false);
const setModal = ref(false);
const tableData = ref([]);
const articleBox = ref(null);
const paginationData = reactive({
  lastPage: "",
  from: "",
  to: "",
  total: "",
  page: 1,
  perPage: 10,
  currentPage: "",
});

const requestQuery = reactive({
  title: null,
  visible: null,
  min_price: null,
  max_price: null,
});

onMounted(() => {
  fetchArticles();
});

const fetchArticles = async () => {
  try {
    loading.value = true;
    const response = await article.get(
      paginationData.page,
      paginationData.perPage,
      requestQuery
    );
    tableData.value = response.data;
    paginationData.lastPage = response.last_page;
    paginationData.from = response.from;
    paginationData.to = response.to;
    paginationData.total = response.total;
    paginationData.currentPage = response.current_page;
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "Oops. Les articles n'ont pas pu être chargés",
      type: "error",
    });
  } finally {
    loading.value = false;
  }
};

const showArticleModal = (article = null) => {
  articleBox.value = article;
  setModal.value = true;
};

const delArticle = async (id) => {
  try {
    loading.value = true;
    await article.del(id).then(() => {
      ElNotification({
        title: "Succès",
        message: "Article supprimé",
        type: "success",
      });
    });
  } catch (error) {
    ElNotification({
      title: "Erreur",
      message: "L'article n'a pas pu être supprimé",
      type: "error",
    });
  }
  fetchArticles();
  loading.value = false;
};

const truncateText = (text) => {
  if (text.length > 70) {
    return text.substring(0, 70) + "...";
  }
  return text;
};
</script>

<template>
  <!-- Breadcrumb -->
  <div class="d-flex justify-content-between align-items-center">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-uppercase">
          <span href="#">FINDLOVE</span>
        </li>
        <li class="breadcrumb-item active text-dark" aria-current="page">
          Articles
        </li>
      </ol>
    </nav>
  </div>
  <hr class="mb-5" />
  <!-- End Breadcrumb -->
  <Loader v-if="loading" />
  <div v-if="!loading" class="my-3 d-flex justify-content-end">
    <router-link :to="{ name: 'addArticle' }" class="btn btn-success">
      Ajouter un article
    </router-link>
  </div>
  <div class="row row-cols-1 row-cols-md-3 row-cols-xl-4">
    <div
      v-for="article in tableData.filter((article) => article.visible)"
      class="card col-sm-6 col-md-4 col-lg-3 mb-2"
      :key="article?.id"
    >
      <img
        :src="article.url ? article.url : '@/assets/placeholder.jpg'"
        class="card-img-top object-fit-cover"
        :alt="article?.title"
        height="200"
      />
      <div class="card-body">
        <div class="card-title d-flex justify-content-between">
          <h5>{{ article?.title }}</h5>
          <span>{{ article?.price }} CFA</span>
        </div>
        <p class="card-text">
          {{ truncateText(article?.description) }}
        </p>
        <div class="d-flex flex-wrap justify-content-between">
          <router-link
            :to="{ name: 'editArticle', params: { id: article?.id } }"
          >
            <el-button type="primary" class="btn-sm px-4"> Editer </el-button>
          </router-link>
          <span class="mx-2"></span
          ><el-popconfirm
            confirm-button-text="Oui"
            cancel-button-text="Non"
            icon-color="red"
            title="Etes-vous sûr de vouloir supprimer cet article ?"
            @confirm="delArticle(article?.id)"
          >
            <template #reference>
              <el-button type="danger" class="btn-sm"> Supprimer </el-button>
            </template>
          </el-popconfirm>
        </div>
      </div>
    </div>
  </div>
</template>
